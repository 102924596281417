import React, { useState } from 'react';
import { useEffect } from 'react';

export function Loader() {
  return (
    <section
      className={`w-6 h-6 border-b-2 border-primary-1 rounded-full animate-spin`}
    ></section>
  );
}
