import { ExclamationIcon } from '@heroicons/react/solid';
import React from 'react';

interface Props {
  message: string | null;
}

export function ErrorMessage({ message }: Props) {
  return (
    <section className="flex flex-row items-start space-x-2 py-2 px-2 text-sm text-red-500 w-full">
      <ExclamationIcon className="w-5 h-5" />
      <section>{message !== null ? message : 'Unknown client error'}</section>
    </section>
  );
}
