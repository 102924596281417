import React from 'react';
import { Authentication } from '../components/Authentication';
import { CenteredLayout } from '../components/Layouts/CenteredLayout';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import SeoHeader from '../components/Shared/SeoHeader';
import { Router } from '@reach/router';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';

export default function AuthPage() {
  return (
    <CenteredLayout>
      <SeoHeader title="Join Now" />
      <Router basepath="/auth">
        <PrivateRoute component={Authentication} path="/" isAuth />
        <NotFoundRedirect path="*" />
      </Router>
    </CenteredLayout>
  );
}
