import React from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  primary?: boolean;
  invert?: boolean;
  block?: boolean;
  loading?: boolean;
}

export const Button: React.FC<Props> = ({
  primary,
  children,
  block = false,
  size = 'sm',
  invert = false,
  disabled = false,
  loading = false,
  ...props
}) => (
  <button
    className={`
    ${
      disabled || loading
        ? 'bg-opacity-80 cursor-not-allowed'
        : 'transform hover:-translate-y-0.5 transition-all duration-150 '
    }
    ${block ? 'w-full mb-4 border' : ''}
    ${
      primary
        ? invert
          ? 'bg-white text-primary-1'
          : 'bg-primary-1 text-white hover:shadow'
        : invert
        ? 'text-white'
        : 'hover:text-primary-1 hover:border-primary-1'
    }  flex items-center justify-center space-x-2 text-${size} ${
      size === 'md' ? 'px-8 py-3' : 'px-4 py-2'
    } rounded-lg hover:bg-opacity-80 uppercase`}
    {...props}
    disabled={loading || disabled}
  >
    {!loading ? (
      children
    ) : (
      <section className="px-2">
        <section
          className={`w-5 h-5 border-b-2 border-primary-3 rounded-full animate-spin`}
        ></section>
      </section>
    )}
  </button>
);
