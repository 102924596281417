import React, { useEffect, useState } from 'react';
import { Loader } from './Shared/Loaders/Loader';
import { Button } from './Shared/Button';

import MicrosoftIcon from '../assets/svg/microsoft.svg';
import GoogleIcon from '../assets/svg/google.svg';
import FacebookIcon from '../assets/svg/facebook.svg';
import YahooIcon from '../assets/svg/yahoo.svg';

import firebase from 'gatsby-plugin-firebase';
import { useAuth } from '../hooks/useAuth';
import { ErrorMessage } from './Shared/ErrorMessage';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import { CardWrapper } from './Shared/Wrapper/CardWrapper';
import { RouteComponentProps } from '@reach/router';
import { isBrowser, isLoading, setAuthLoading } from '../services/auth';
import { APP_AUTH_LOADING } from '../config/constants';
import { useAuthenticateUserMutation } from '../graphql/__types';
import { toast } from 'react-toastify';

export function Authentication(_: RouteComponentProps) {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);

  const [authenticateUser] = useAuthenticateUserMutation();

  const {
    isAuthenticating,
    authenticate,
    isError,
    errorMessage,
    parseUser,
    redirectAfterAuth,
    handleErrorMessage,
    signOut,
  } = useAuth();

  useEffect(() => {
    const handleRedirectSignIn = async () => {
      try {
        const result = await firebase.auth().getRedirectResult();

        if (result.user !== null) {
          const user = await result.user.getIdTokenResult();

          parseUser(user);
          setAuthLoading('close');

          await authenticateUser({ variables: { id: result.user.uid } });
          toast.success('Authentication successful');

          redirectAfterAuth();
        }
      } catch (error) {
        handleErrorMessage(error);
        setAuthLoading('close');
        setIsAuthLoading(false);
        signOut();
      }
    };

    handleRedirectSignIn();
  }, []);

  setTimeout(() => {
    if (isLoading()) {
      setAuthLoading('close');
      setIsAuthLoading(false);
    }
  }, 10000);

  useEffect(() => {
    if (isBrowser()) {
      const loading = window.localStorage.getItem(APP_AUTH_LOADING);

      if (loading !== null) {
        const loadingValue = JSON.parse(loading);

        if (loadingValue.loading) setIsAuthLoading(true);
      }
    }
  }, []);

  return (
    <section className="min-h-screen flex items-center justify-center px-6 py-6 bg-primary-3">
      <section className="hidden md:flex md:w-4/12">
        <section className="mx-auto">
          <StaticImage
            className="cursor-pointer hover:opacity-80"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={210}
            height={120}
            src={`../assets/images/nstw_logo.png`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/')}
          />
        </section>
      </section>

      <section className="w-full md:w-4/12">
        <CardWrapper>
          <section className="py-6">
            <section className="md:hidden mb-6 text-center">
              <StaticImage
                className="cursor-pointer hover:opacity"
                quality={95}
                formats={['webp', 'auto', 'avif']}
                width={150}
                height={80}
                src={`../assets/images/nstw_logo.png`}
                alt="NSTW Logo"
                placeholder="tracedSVG"
                onClick={() => navigate('/')}
              />
            </section>

            <h1 className="text-2xl text-center">Welcome Back</h1>

            <section className="flex flex-col items-center px-6 md:px-12 justify-center h-72">
              {isAuthenticating || isAuthLoading ? (
                <Loader />
              ) : (
                <>
                  <Button size="xs" block onClick={() => authenticate('MS')}>
                    <MicrosoftIcon />
                    <span>Continue with Microsoft</span>
                  </Button>

                  <Button size="xs" block onClick={() => authenticate('GO')}>
                    <GoogleIcon />
                    <span>Continue with Google</span>
                  </Button>

                  <Button size="xs" block onClick={() => authenticate('FB')}>
                    <FacebookIcon />
                    <span>Continue with Facebook</span>
                  </Button>

                  <Button size="xs" block onClick={() => authenticate('YH')}>
                    <YahooIcon />
                    <span>Continue with Yahoo Mail</span>
                  </Button>
                </>
              )}

              {isError ? <ErrorMessage message={errorMessage} /> : null}
            </section>

            <section className="w-full md:w-9/12 mx-auto">
              <section className="text-center text-xs leading-4">
                By continuing you agree to our{' '}
                <Link
                  className="font-bold text-primary-1"
                  to="/terms-of-service"
                >
                  Terms of Service
                </Link>{' '}
                and acknowledge that our{' '}
                <Link className="font-bold text-primary-1" to="/privacy-policy">
                  Privacy Policy
                </Link>{' '}
                applies to you and if you are a DOST 1 Staff, you are advised to{' '}
                <span className="font-bold text-primary-1">
                  Continue with Microsoft
                </span>{' '}
                to proceed
              </section>
            </section>
          </section>
        </CardWrapper>
      </section>
    </section>
  );
}
