import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';

export function NotFoundRedirect(_: RouteComponentProps) {
  useEffect(() => {
    navigate('/404', { replace: true });
  }, []);

  return <React.Fragment />;
}
