import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { useAuth } from '../../hooks/useAuth';
import { getUser, isBrowser, isLoading, isLoggedIn } from '../../services/auth';
import { useLocation } from '@reach/router';

export function PrivateRoute({
  component: Component,
  roles,
  isAuth,
  isCheckpoint,
  ...otherProps
}: any) {
  const { pathname } = useLocation();
  const { checkUser } = useAuth();

  useEffect(() => {
    checkUser();
  }, []);

  function isAllowed() {
    if (isLoggedIn()) {
      if (!!roles) {
        const isRoleAllowed = roles.find(
          (role: string) => role === getUser().role,
        );
        if (isRoleAllowed) return true;
      }

      if (getUser().isCheckpoint) return true;
    }

    return false;
  }

  if (isLoggedIn() && isBrowser()) {
    if (pathname === '/auth' || !isAllowed()) {
      navigate('/404', { replace: true });
    }
  }

  if (!isLoggedIn() && isBrowser()) {
    if (pathname !== '/auth' && pathname !== '/auth/' && !isLoading()) {
      navigate('/404', { replace: true });
    }
  }

  return <Component {...otherProps} />;
}
